.messages {
  & .user {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;

    & .message {
      color: white;
      background-color: rgb(3, 127, 12);
      padding: 8px 12px 10px 12px;
      border-radius: 12px;
      border-bottom-right-radius: 0;
      height: fit-content;
    }

    & .subtext {
      text-align: right;
      color: #7d8998;
      font-size: 12px;

      & a {
        font-size: 12px!important;
      }
    }
  }

  & .student {
    display: flex;
    gap: 10px;

    & input {
      background-color: blue !important;
    }

    & .message {
      color: white;
      background-color: #7d8998;
      padding: 8px 12px 10px 12px;
      border-radius: 12px;
      border-bottom-left-radius: 0;
      height: fit-content;
    }

    & .subtext {
      color: #7d8998;
      font-size: 12px;

      & a {
        font-size: 12px !important;
      }
    }
  }
}
